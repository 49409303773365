import i18next from 'i18next';

i18next
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: 'pl', // 'en' | 'pl'
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translation: {
            menu: [
                { name: "About us" , url: "#about"},
                // { name: "Products" , url: "#products"},
                { name: "Contact" , url: "#contact"}
              ],
            uc: {
                title: "Website under construction."
            },
            contact: {
                title:"Contact",
                name: "Full of Taste Sp. z o.o. Sp. k",
                street: "ul.H.Modrzejewskiej 15",
                zip_city: "75-950 Koszalin",
                country: "Poland",
                phone: "phone:"
            },
            products: {
                title: "Products",
                salt: {
                    title: "Salted crisps (120 g)",
                    features: ["Gluten-free", "No palm oil", "No preservatives"]
                },
                bacon: {
                    title: "Bacon-flavoured crisps (140 g)",
                    features: ["Gluten-free", "No palm oil", "No preservatives"]
                },
                onion: {
                    title: "Onion-flavoured crisps (140 g)",
                    features: ["No palm oil", "No preservatives"]
                },
                sour_cream: {
                    title: "Sour cream and onion-flavoured chickpeas crisps (100 g)",
                    features: ["Gluten-free", "No palm oil", "No preservatives"]
                },
                sour_cream_basil: {
                    title: "Sour cream and basil-flavoured chickpeas crisps (100 g)",
                    features: ["Gluten-free", "No palm oil", "No preservatives"]
                },
                ketchup: {
                    title: "Ketchup-flavoured crisps (120 g)",
                    features: ["Gluten-free", "No palm oil", "No preservatives"]
                },
                ghosts: {
                    title: "Ghosts crisps (120 g)",
                    features: ["Gluten-free", "No palm oil", "No preservatives"]
                }
            },
            about: {
              title: "About us",
              content_1: "Full of Taste Ltd. was started by people with years of experience in the snacks industry, who wished to fulfill the needs of customers wanting high quality, tasty and healthy snacks. Our mission is creating snacks that won’t have you choosing between taste and health. Our crisps, puffs and wafers are all offered under the brand of TopSnackers.",
                content_2: "Our products are made on sunflower oil, from grains and legumes with no conservatives, no colorants and no flavour enhancers. We don’t use palm oil at any stage of production. Our company also has the Gluten-Free certification.",
                content_3: "And what do they taste like? See for yourself! :)"
            },
        },
      },
      pl: {
        translation: {
            menu: [
                { name: "O nas" , url: "#about"},
                // { name: "Produkty" , url: "#products"},
                { name: "Kontakt" , url: "#contact"}
              ],
            uc: {
                title: "Strona w przebudowie."
            },
            contact: {
                title:"Kontakt",
                name: "Full of Taste Sp. z o.o. Sp. k",
                street: "ul.H.Modrzejewskiej 15",
                zip_city: "75-950 Koszalin",
                country: "Polska",
                phone: "tel."
            },
            products: {
                title: "Produkty",
                salt: {
                    title: "Prażynki solone (120g)",
                    features: ["produkt bezglutenowy", "bez oleju palmowego", "bez konserwantów"]
                },
                bacon: {
                    title: "Prażynki bekonowe (140g)",
                    features: ["produkt bezglutenowy", "bez oleju palmowego", "bez konserwantów"]
                },
                onion: {
                    title: "Prażynki cebulowe (140g)",
                    features: ["bez oleju palmowego", "bez konserwantów"]
                },
                sour_cream: {
                    title: "Prażynki z soczewicy o smaku śmietanki (100g)",
                    features: ["produkt bezglutenowy", "bez oleju palmowego", "bez konserwantów"]
                },
                sour_cream_basil: {
                    title: "Prażynki z soczewicy o smaku śmietanki z bazylią (100g)",
                    features: ["produkt bezglutenowy", "bez oleju palmowego", "bez konserwantów"]
                },
                ketchup: {
                    title: "Prażynki ketchupowe (120g)",
                    features: ["produkt bezglutenowy", "bez oleju palmowego", "bez konserwantów"]
                },
                ghosts: {
                    title: "Prażynki Lekkoduszki (120g)",
                    features: ["produkt bezglutenowy", "bez oleju palmowego", "bez konserwantów"]
                }
            },
            about: {
                title: "O nas",
                content_1: "Firma Full of Taste została założona przez ludzi z pasją i wieloletnim doświadczeniem w branży przekąskowej, w odpowiedzi na rosnące zapotrzebowanie konsumentów na jakościowe, smaczne i zdrowe przekąski. Naszą misją jest dostarczanie konsumentom takich produktów, w których nie trzeba wybierać między smakiem a zdrowiem. Nasze chrupki, prażynki i wafelki oferujemy pod marką TopSnackers.",
                content_2: "Wytwarzane są na bazie zbóż i roślin strączkowych, bez użycia sztucznych konserwantów, barwników czy wzmacniaczy smaku, na oleju słonecznikowym. W trosce o środowisko, na żadnym etapie nie używamy oleju palmowego. Posiadamy również certyfikat Przekreślonego Kłosa dla produktów bezglutenowych",
                content_3: "A jak smakują? Zapraszamy do spróbowania! :)"
            },
        },
      },
    },
  })

export default i18next