import React, { Suspense } from 'react';
import i18n from '../../i18n'

function About(props) {
    return (    
                <>
                    <h2>{i18n.t('about.title', props.lang)}</h2>
                    <p>
                        {i18n.t('about.content_1', props.lang)}
                    </p>
                    <p>
                        {i18n.t('about.content_2', props.lang)}
                    </p>
                    <p>
                        {i18n.t('about.content_3', props.lang)}
                    </p>
                </>
    )
}

export default About