import React from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

class Menu extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            links: [
                { name: "O nas" , url: "#about"},
                { name: "Produkty" , url: "#products"},
                { name: "Kontakt" , url: "#contact"}
            ]
        }
    }
    render(){
        return (
            <div className={"menu"}>
                {/* <div className={"dots"}></div>
                <ul>
                    {this.props.menu.map(
                        (element, index)=> {
                            return <a key={index} href={element.url}>
                                        {element.name}
                                    </a>
                        }
                    )}
                </ul>
                <div className={"dots"}></div> */}
            </div>
        )
    }
}

export default Menu
