import React, { Suspense } from 'react';
import i18n from '../../i18n'

function UC(props) {
    return (    
                <>
                    <h1 className="center construction">{i18n.t('uc.title', props.lang)}</h1>
                </>
    )
}

export default UC