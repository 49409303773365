import React from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import logo from './static/logo@2x.png'
import snack1 from './static/snackers_1@2x.jpg'
import snack2 from './static/snackers_2@2x.jpg'
import Menu from './components/Menu'
import Content from './components/Content'
import './App.scss'
import i18n from './i18n'


class App extends React.Component {
  constructor(){
    super()
    this.state = {
      lang: 'pl'
    }
  }
  changeLanguage = lng => {
    this.setState({lang: lng})
    i18n.changeLanguage(lng)
  }
  
  render(){
    return (
      <div className="App">
        <Router>
        <>
        <button className={'lang'} onClick={()=>this.changeLanguage('pl')}>PL</button>
        <button className={'lang'} onClick={()=>this.changeLanguage('en')}>ENG</button>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <Menu menu={i18n.t('menu', { returnObjects: true }, this.state.lang)}/>
        </header>
        <article>
          <Content lang={this.state.lang}/>
        </article>
        <div className={"left chips"}>
            <img src={snack1} alt="chips"/>
        </div>
        <div className={"right chips"}>
            <img src={snack2} alt="chips"/>
        </div>
        </>
        </Router>
      </div>
    );
  }
}

export default App;
