import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import { checkLanguage } from '../helpers'
import Generic from './content/Generic'
import About from './content/About'
import UC from './content/UC'
import Products from './content/Products'
import Contact from './content/Contact'
import i18n from '../i18n'

class Content extends Component {
    constructor(props){
        super(props)
        this.state = null
    }

    render(){
        return (
            <div className="App-content">
                <div className={"dots-2"}></div>
                {/* <Route path="/onas" component={About} />
                <Route exact path="/" component={About} />
                <Route path="/produkty" component={Products} />
                <Route path="/kontakt"  component={Contact} /> */}
                <Generic id={"uc"} content={<UC lang={this.props.lang}/>}/>
                <Generic id={"about"} content={<About lang={this.props.lang}/>}/>
                {/* <Generic id={"products"} content={<Products lang={this.props.lang}/>}/> */}
                <Contact lang={this.props.lang}/>
                <div className={"dots-2"}></div>
            </div>
        )
    }
}

export default Content
