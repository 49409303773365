import React, { Component } from 'react'
import i18n from '../../i18n'

class Contact extends Component {
    render(){
        return (
            <div id={'contact'}>
                <h2>{i18n.t('contact.title', this.props.lang)}</h2>
                <p>
                {i18n.t('contact.name', this.props.lang)}
                <br/>{i18n.t('contact.street', this.props.lang)}
                <br/>{i18n.t('contact.zip_city', this.props.lang)}
                <br/>{i18n.t('contact.country', this.props.lang)}
                <br/>e-mail : <a href="mailto:dok@fulloftaste.pl">dok@fulloftaste.pl</a>
                <br/>{i18n.t('contact.phone', this.props.lang)} +48 517 769 248
                </p>
            </div>
        )
    }
}

export default Contact
