import React from 'react'


const Generic = (props) => {
        return (
            <div id={props.id} className="about">
                {props.content}
            </div>
        )
}

export default Generic
